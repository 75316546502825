import React from "react"
import { graphql, Link } from "gatsby"
import * as newsTemplateStyles from "./newsTemplate.module.scss"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Pager from "../components/pager"
import Seo from "../components/seo"

const NewsTemplate = ({ data, pageContext }) => {
  const { posts } = data.blog

  return (
    <div className={newsTemplateStyles.wrapper}>
      <Seo title="News" />
      <div aria-hidden="true" className={newsTemplateStyles.redLine}></div>
      <div className={newsTemplateStyles.contentWrapper}>
        <h1 className={newsTemplateStyles.visuallyHiddenHeader}>
          The Latest News
        </h1>
        <StaticImage
          src="../images/news/newscaster.png"
          placeholder="tracedSVG"
          className={newsTemplateStyles.newscaster}
          alt="Illustration of vintage newscaster with eyeball head, with red lightning bolts on either side"
        />
        <div className={newsTemplateStyles.postsGrid}>
          {posts.map(post => (
            <article className={newsTemplateStyles.postWrapper} key={post.id}>
              <Link to={`${__dirname}news${post.fields.slug}`}>
                <GatsbyImage
                  className={newsTemplateStyles.featuredImage}
                  style={{
                    display: `block`,
                    marginLeft: `auto`,
                    marginRight: `auto`,
                  }}
                  image={
                    post.frontmatter.featuredImage?.childImageSharp
                      ?.gatsbyImageData
                  }
                  alt="Featured post image for blog entry"
                />
              </Link>
              <small className={newsTemplateStyles.postDate}>
                {post.frontmatter.date}
              </small>
              <Link
                className={newsTemplateStyles.postLink}
                to={`${__dirname}news${post.fields.slug}`}
              >
                <h2 className={newsTemplateStyles.postHeader}>
                  {post.frontmatter.title}
                </h2>
              </Link>
              <p className={newsTemplateStyles.postExcerpt}>{post.excerpt}</p>
            </article>
          ))}
        </div>
      </div>
      <Pager pageContext={pageContext} />
    </div>
  )
}

export const data = graphql`
  query($skip: Int!, $limit: Int!) {
    blog: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      skip: $skip
      limit: $limit
      filter: { fileAbsolutePath: { regex: "/content/blog/" } }
    ) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          featuredImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
              id
            }
          }
        }
        excerpt
        id
      }
    }
  }
`

export default NewsTemplate
