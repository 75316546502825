import React from "react"
import { Link } from "gatsby"
import * as pagerStyles from "./pager.module.scss"
import {
  IoIosArrowDroprightCircle,
  IoIosArrowDropleftCircle,
} from "react-icons/io"

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext
  return (
    <div className={pagerStyles.wrapper}>
      <div className={pagerStyles.wrapper}>
        {previousPagePath && (
          <div>
            <Link className={pagerStyles.previous} to={previousPagePath}>
              <div className={pagerStyles.previousArrowWrapper}>
                <span>Newer Posts</span>
                <span className={pagerStyles.arrowPrevious}>
                  <IoIosArrowDropleftCircle />
                </span>
              </div>
            </Link>
          </div>
        )}
        {nextPagePath && (
          <div>
            <Link className={pagerStyles.next} to={nextPagePath}>
              <div>
                <span>Older Posts</span>{" "}
                <span className={pagerStyles.arrowNext}>
                  <IoIosArrowDroprightCircle />
                </span>
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default Pager
