// extracted by mini-css-extract-plugin
export var contentWrapper = "newsTemplate-module--contentWrapper--vcMhZ";
export var featuredImage = "newsTemplate-module--featuredImage--8XJ-a";
export var mainHeader = "newsTemplate-module--mainHeader--X+vWU";
export var newscaster = "newsTemplate-module--newscaster--1a0fs";
export var postDate = "newsTemplate-module--postDate--HItHk";
export var postExcerpt = "newsTemplate-module--postExcerpt--DB+P-";
export var postHeader = "newsTemplate-module--postHeader--H2Rj5";
export var postLink = "newsTemplate-module--postLink--ohgLJ";
export var postWrapper = "newsTemplate-module--postWrapper--CAuWX";
export var postsGrid = "newsTemplate-module--postsGrid--ADr7e";
export var redLine = "newsTemplate-module--redLine--eHo5m";
export var visuallyHiddenHeader = "newsTemplate-module--visuallyHiddenHeader--7pgkh";
export var wrapper = "newsTemplate-module--wrapper--M4mes";